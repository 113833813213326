import React, { useEffect, useState } from 'react'

function ProductAnnouncement() {
  return (
    <div className="text-center mb-8">
      <h2 className="text-3xl font-bold text-purple-400 mb-2">¡Ya está aquí!</h2>
      <p className="text-xl text-gray-300">La espera ha terminado. Consigue tu camiseta Foirola ahora.</p>
    </div>
  )
}

export default function HomePage() {
  const [isShopifyLoaded, setIsShopifyLoaded] = useState(false);

  useEffect(() => {
    const loadShopifyScript = () => {
      const script = document.createElement('script');
      script.src = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
      script.async = true;
      script.onload = () => {
        console.log('Shopify script loaded');
        setIsShopifyLoaded(true);
      };
      document.body.appendChild(script);
    };

    loadShopifyScript();

    return () => {
      const script = document.querySelector('script[src="https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js"]');
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    if (isShopifyLoaded && window.ShopifyBuy) {
      const client = window.ShopifyBuy.buildClient({
        domain: 'im7jcy-9z.myshopify.com',
        storefrontAccessToken: 'f7695a55fa36aa9504f3ecc4fa2d2ae4',
      });

      window.ShopifyBuy.UI.onReady(client).then((ui) => {
        ui.createComponent('product', {
          id: '9568713474387',
          node: document.getElementById('shopify-product-component'),
          moneyFormat: '%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D',
          options: {
            product: {
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0",
                    "margin-bottom": "50px"
                  },
                  "text-align": "left"
                },
                title: {
                  "font-family": "inherit",
                  "font-weight": "bold",
                  "font-size": "2rem",
                  "color": "#a78bfa"
                },
                price: {
                  "font-family": "inherit",
                  "font-weight": "normal",
                  "font-size": "1.5rem",
                  "color": "#e5e7eb"
                },
                compareAt: {
                  "font-family": "inherit",
                  "font-weight": "normal",
                  "font-size": "1.25rem",
                  "color": "#9ca3af"
                },
                unitPrice: {
                  "font-family": "inherit",
                  "font-weight": "normal",
                  "font-size": "1.25rem",
                  "color": "#9ca3af"
                },
                description: {
                  "font-family": "inherit",
                  "font-weight": "normal",
                  "font-size": "1rem",
                  "color": "#d1d5db"
                },
                button: {
                  "font-family": "inherit",
                  "font-weight": "bold",
                  "background-color": "#8b5cf6",
                  "color": "#ffffff",
                  "padding": "0.75rem 1.5rem",
                  "border-radius": "0.25rem",
                  ":hover": {
                    "background-color": "#7c3aed"
                  },
                  ":focus": {
                    "background-color": "#7c3aed"
                  }
                },
                select: {
                  "font-family": "inherit",
                  "font-size": "1rem",
                  "padding": "0.5rem",
                  "border-radius": "0.25rem",
                  "background-color": "#4B5563",
                  "color": "#E5E7EB",
                  "border": "1px solid #6B7280"
                },
                options: {
                  "color": "#E5E7EB",
                  "font-weight": "bold",
                  "margin-bottom": "0.5rem"
                },
                img: {
                  "transition": "transform 0.3s ease-in-out",
                  ":hover": {
                    "transform": "scale(1.1)"
                  }
                },
                imgWrapper: {
                  "overflow": "hidden"
                }
              },
              layout: "horizontal",
              contents: {
                img: false,
                imgWithCarousel: true,
                description: true
              },
              width: "100%",
              text: {
                button: "Añadir al carrito"
              },
              events: {
                afterRender: function(component) {
                  const images = component.node.querySelectorAll('.shopify-buy__product__variant-img');
                  images.forEach(img => {
                    img.addEventListener('mousemove', function(e) {
                      const rect = e.target.getBoundingClientRect();
                      const x = e.clientX - rect.left;
                      const y = e.clientY - rect.top;
                      const xPercent = (x / rect.width) * 100;
                      const yPercent = (y / rect.height) * 100;
                      this.style.transformOrigin = `${xPercent}% ${yPercent}%`;
                    });
                  });
                }
              }
            },
            productSet: {
              styles: {
                products: {
                  "@media (min-width: 601px)": {
                    "margin-left": "-20px"
                  }
                }
              }
            },
            modalProduct: {
              contents: {
                img: false,
                imgWithCarousel: true,
                button: false,
                buttonWithQuantity: true
              },
              styles: {
                product: {
                  "@media (min-width: 601px)": {
                    "max-width": "100%",
                    "margin-left": "0px",
                    "margin-bottom": "0px"
                  }
                },
                img: {
                  "transition": "transform 0.3s ease-in-out",
                  ":hover": {
                    "transform": "scale(1.1)"
                  }
                },
                imgWrapper: {
                  "overflow": "hidden"
                }
              }
            },
            cart: {
              styles: {
                button: {
                  "font-family": "inherit",
                  "font-weight": "bold",
                  "background-color": "#8b5cf6",
                  ":hover": {
                    "background-color": "#7c3aed"
                  },
                  ":focus": {
                    "background-color": "#7c3aed"
                  }
                }
              },
              text: {
                total: "Subtotal",
                button: "Checkout"
              }
            },
            toggle: {
              styles: {
                toggle: {
                  "font-family": "inherit",
                  "background-color": "#8b5cf6",
                  ":hover": {
                    "background-color": "#7c3aed"
                  },
                  ":focus": {
                    "background-color": "#7c3aed"
                  }
                }
              }
            }
          },
        });
      });
    }
  }, [isShopifyLoaded]);

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 font-sans">
      <header className="py-4 px-4 sm:px-6 lg:px-8 border-b border-gray-800 sticky top-0 bg-gray-900 bg-opacity-95 z-20">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <img
              src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/FOIROLA_Logo_LOGO-BYa7fAo1Qnj6q7nk5ej7gwykI7U3W3.png"
              alt="Logo de Foirola"
              className="w-12 h-12 mr-4"
            />
          </div>
          <nav className="flex items-center space-x-4">
            <a href="#producto" className="text-sm font-medium text-purple-300 hover:text-pink-400 transition-colors">Producto</a>
            <a href="#sobre-nosotros" className="text-sm font-medium text-purple-300 hover:text-pink-400 transition-colors">Sobre Nosotros</a>
            <a href="#contacto" className="text-sm font-medium text-purple-300 hover:text-pink-400 transition-colors">Contacto</a>
          </nav>
        </div>
      </header>

      <main>
        <section id="producto" className="py-20 px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 sm:text-5xl sm:tracking-tight lg:text-6xl mb-12 text-center">
              Se vino la cosa. ¿Estás listo?
            </h1>
            <ProductAnnouncement />
            <div id="shopify-product-component" className="bg-gray-800 p-6 rounded-lg"></div>
          </div>
        </section>

        <section id="sobre-nosotros" className="py-20 px-4 sm:px-6 lg:px-8 bg-gray-800 bg-opacity-95">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold text-purple-400 mb-4">Sobre Foirola</h2>
            <p className="text-xl text-gray-300 mb-6">
              Tres zumbaos dándole voz al lore de nuestra tierra. <br />¿Estás preparado?
            </p>
            <p className="text-xl text-gray-300">
              A través de nuestros esfuerzos creativos, aspiramos a mostrar el corazón y el alma de Fuengirola, dando vida a sus historias de maneras emocionantes e inesperadas.<br />Siempre desde el humor y la parodia.
            </p>
          </div>
        </section>

        <section id="contacto" className="py-20 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md mx-auto">
            <h2 className="text-3xl font-extrabold text-purple-400 text-center mb-8">Contáctanos</h2>
            <form className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-300">Nombre</label>
                <input id="name" name="name" required className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white" />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-300">Correo electrónico</label>
                <input type="email" id="email" name="email" required className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white" />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-300">Mensaje</label>
                <textarea id="message" name="message" rows={4} required className="w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white"></textarea>
              </div>
              <button type="submit" className="w-full bg-purple-500 text-white py-2 px-4 rounded hover:bg-purple-600">
                Enviar
              </button>
            </form>
            <div className="mt-8 text-center">
              <p className="text-gray-300">O envíanos un correo directamente a:</p>
              <a href="mailto:foirola29640@gmail.com" className="text-purple-400 hover:text-pink-400 transition-colors font-bold">foirola29640@gmail.com</a>
            </div>
            <div className="mt-6 text-center">
              <a
                href="https://www.instagram.com/foirola.brand"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center text-purple-400 hover:text-pink-400 transition-colors"
              >
                <span role="img" aria-label="Instagram" className="mr-2">📷</span>
                <span>Síguenos en Instagram</span>
              </a>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-gray-800 bg-opacity-95 border-t border-gray-700 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto text-center">
          <p className="text-base text-gray-400">&copy; {new Date().getFullYear()} Foirola. Todos los derechos reservados.</p>
        </div>
      </footer>
    </div>
  )
}